/*Large Device :1200*/
@media only screen and (min-width: 1600px){


}

/*Laptop Device :1200*/
@media (min-width: 1200px) and (max-width: 1500px){


}


/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1199px) {
.post__itam .content h3 {
	font-size: 16px;
}


}


/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  .minicart-content-wrapper {
    max-height: 450px;
  }
  .arrows_style .owl-nav div.owl-prev {
    left: 0;
  }
  .arrows_style .owl-nav div.owl-next {
    right: 0;
  }
  .post__itam .content {
	padding: 20px 20px 15px;
}
 .post__itam .content h3 {
	font-size: 16px;
}
 .wn__pagination {
	margin-top: 50px;
} 
  

}

/* small mobile :320px. */
@media (max-width: 767px) {

.block-minicart {
	right: -70px;
}  
.minicart-content-wrapper {
	width: 250px;
	max-height: 300px;
	padding: 20px;
}
 .mini-cart-item .content h6 {
	font-size: 14px;
} 
  
.blog-details .comment_respond .comment__form .input__box.one--third {
	width: 100%;
}
 #googleMap {
	height: 300px;
}
.product__info__main .box-tocart {
	flex-wrap: wrap;
}
.product__info__main .product-addto-links a.wishlist {
	display: block;
}
.box-tocart .product-addto-links.clearfix {
	margin-top: 15px;
}
  .product__info__main .box-tocart .addtocart__actions .tocart {
    padding: 0 15px;
  }
  .product__info__detailed .pro_details_nav a.nav-item + .nav-item {
	margin-left: 25px;
}
  
}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

.box-tocart .product-addto-links.clearfix {
	margin-top: 0;
}

}
